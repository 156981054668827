import { http } from "./config";
import authHeader from "./auth-header";

export default {
  salvar_regra: (regra) => {
    return http.post("/mensageria-dash/salva", regra, {
      headers: authHeader(),
    });
  },
  lista_regras: (tipo) => {
    return http.get(`/mensageria-dash/lista-${tipo}`, {
      headers: authHeader(),
    });
  },
  put_regra: (id, body) => {
    return http.put(`/mensageria-dash/edita/${id}`, body, {
      headers: authHeader(),
    });
  },
  lista_disparos: (dtinicial, dtfinal, empresa) => {
    const params = {
      'data-inicial': dtinicial,
      'data-final': dtfinal,
      'empresa': empresa
    }
    return http.get('/mensageria-dash/lista-cobranca-sms', {
      params: params,
      headers: authHeader()
    })
  }
};
 