<template>
  <v-app style="background-color:#f8f9fb;">
    <v-container style="background-color:#eee" id="financeiro" fluid tag="section">
      <v-row>
        <v-col cols="12">
          <base-material-card icon="mdi-link-variant" title="Lembrete" color="rgb(71 130 180)"
            style="margin-top:60px !important; padding-top:10px !important" class="px-5 py-0">

            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row style="margin-top: 1rem;">
                <!-- Empresa -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-select v-model="regra.empresa" :items="filteredEmpresas" label="Empresa" dense required
                    :rules="[v => v != null && v !== '' || 'Este campo é obrigatório']" solo-inverted></v-select>
                </v-col>

                <!-- Tipo Contrato -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-select dense solo-inverted required
                    :rules="[v => v != null && v !== '' || 'Este campo é obrigatório']"
                    :items="['Planos', 'Cemitérios']" v-model="regra.tipo" label="Tipo Contrato"></v-select>
                </v-col>

                <!-- Tipo da Parcela -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-select dense solo-inverted required
                    :rules="[v => v != null && v !== '' || 'Este campo é obrigatório']" :items="['Contrato', 'Venda']"
                    v-model="regra.tipo_parcela" label="Tipo da Parcela"></v-select>
                </v-col>

                <!-- Dias de Atraso -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field dense solo-inverted v-model="regra.dias_vencido" label="Dias de Antecedência"
                    type="number" :max="10000" :min="1"
                    :rules="[v => !!v || 'Este campo é obrigatório']"></v-text-field>
                </v-col>

                <!-- Horário -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-text-field dense solo-inverted v-model="regra.horario" label="Horário" type="time"
                    :rules="[v => !!v || 'Este campo é obrigatório']"></v-text-field>
                </v-col>

                <!-- Data Inicial -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-menu v-model="isStartDatePickerOpen" :close-on-content-click="false" transition="scale-transition"
                    offset-y bottom>
                    <template #activator="{ on }">
                      <v-text-field v-model="regra.data_inicial" label="Data Inicial" dense solo-inverted readonly
                        required :rules="[v => v != null && v !== '' || 'Este campo é obrigatório']"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="localSelectedStartDateISO"
                      @input="handleStartDateSelection"></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Data Final -->
                <v-col cols="12" sm="6" md="3" class="py-0">
                  <v-menu v-model="isEndDatePickerOpen" :close-on-content-click="false" transition="scale-transition"
                    offset-y bottom>
                    <template #activator="{ on }">
                      <v-text-field v-model="regra.data_final" label="Data Final" dense solo-inverted readonly v-on="on"
                        :disabled="regra.indeterminate"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="localSelectedEndDateISO" :disabled="regra.indeterminate"
                      @input="handleEndDateSelection"></v-date-picker>
                  </v-menu>
                </v-col>

                <!-- Ativar a Data Final -->
                <v-switch color="blue" v-model="regra.indeterminate" label="Final Indeterminado"
                  class="mt-0"></v-switch>

                <!-- Mensagem para Disparo -->
                <v-col cols="8" class="py-0">
                  <v-textarea dense solo-inverted label="Mensagem para Disparo" filled auto-grow rows="1"
                    row-height="20" counter="180" maxlength="180" v-model="regra.mensagem" required
                    :rules="[v => v != null && v !== '' || 'Este campo é obrigatório']"></v-textarea>

                  <v-text class="text-primary text-decoration-underline" @click="dialog = true"
                    style="cursor: pointer;">
                    Variáveis disponíveis
                  </v-text>

                </v-col>
                <v-col cols="4" class="py-0">
                  <!-- Ativar a Data Final -->
                  <v-switch color="blue" v-model="regra.status" label="Status da Regra" class="mt-0"></v-switch>
                </v-col>

              </v-row>

              <v-card-actions class="d-flex justify-end">
                <v-btn small v-if="!showEditar" :loading="loadingCreate" color="primary" @click="adiciona_regra()"
                  class="pr-4">
                  <v-icon left>mdi-plus</v-icon>Nova Regra
                </v-btn>
                <v-btn outlined small v-if="showEditar" color="red" @click="clear_atualizacao()">
                  <v-icon left>mdi-cancel</v-icon>Cancelar Edição
                </v-btn>
                <v-btn small v-if="showEditar" :loading="loadingUpdate" color="warning" @click="atualizar_regra(regra)"
                  class="mr-0">
                  <v-icon left>mdi-update</v-icon>Atualizar Regra
                </v-btn>

              </v-card-actions>
            </v-form>


            <v-data-table style="margin-top: 1rem;" :headers="ranking_header" :items="listaRegras" :items-per-page="50"
              items-per-page-text="Linhas por página" class="elevation-0">
              <br /> <br /> <br />
              <template v-slot:top>
                <v-progress-linear v-if="isLoading" indeterminate color="rgb(71 130 180)"></v-progress-linear>
              </template>
              <template v-slot:item="row">
                <tr :class="{ 'cor-row': row.index % 2 !== 0 }">
                  <td class="linha">
                    <v-chip :color="getStatusCor(row.item.empresa)" text-color="white">
                      {{ row.item.empresa }}
                    </v-chip>
                  </td>
                  <td class="linha">
                    {{ row.item.mensagem }}
                  </td>
                  <td class="linha">
                    {{ row.item.tipo_parcela }}
                  </td>

                  <td class="linha">
                    {{ row.item.tipo }}
                  </td>

                  <td class="linha">
                    {{ row.item.dias_vencido }}
                  </td>

                  <td class="linha">
                    {{ row.item.data_envio }}
                  </td>
                  <td class="linha">
                    <v-chip :color="getStatusDisparoCor(row.item.status)" text-color="white">
                      {{ getStatus(row.item.status) }}
                    </v-chip>
                  </td>
                  <td class="linha">
                    <v-btn @click="editarRegra(row.item)" text small color="warning">
                      <v-icon dark>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="800px">
        <v-card>
          <v-card-title class="headline">Variáveis Disponíveis</v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="variaveis" dense hide-default-footer>
              <template v-slot:item="row">
                <tr :class="{ 'cor-row': row.index % 2 !== 0 }">
                  <!-- Nome da variável + Botão Copiar -->
                  <td class="linha" style="width: 20%;">
                    <div class="d-flex align-center">
                      <code>{{ row.item.nome }}</code>
                      <v-spacer></v-spacer>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon small v-bind="attrs" v-on="on" @click="copiarTexto(row.item.nome)">
                            <v-icon size="17" center>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                        <span>Copiar</span>
                      </v-tooltip>
                    </div>
                  </td>

                  <!-- Descrição -->
                  <td class="linha" style="width: 20%;">
                    <v-chip small color="blue lighten-4">{{ row.item.descricao }}</v-chip>
                  </td>

                  <!-- Exemplo -->
                  <td class="linha" style="width: 30%;">
                    <p class="text-body-2 text-grey-darken-1" style="max-width: 100%;">{{ row.item.exemplo }}</p>
                  </td>
                </tr>
              </template>
            </v-data-table>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" outlined small @click="dialog = false">
              <v-icon left>mdi-close</v-icon>Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
  </v-app>
</template>
<script>
import { ContentLoader } from 'vue-content-loader'
import VuetifyMoney from "./components/VuetifyMoney.vue";
import mensageria from '../../services/mensageria';

export default {
  name: 'MensageriaLembrete',
  components: {
    apexcharts:
      ContentLoader,
    "vuetify-money": VuetifyMoney
  },

  created() {
  },

  mounted() {
    this.consultar_regras()
  },

  computed: {
    filteredEmpresas() {
      return this.empresas.filter(empresa => this.hasPermission(empresa));
    },
  },

  data() {
    return {
      variaveis: [
        { nome: "{{contrato}}", descricao: "Contrato do Cliente", exemplo: "MP012234" },
        { nome: "{{identificacao}}", descricao: "Identificação Fatura", exemplo: "03/25" },
        { nome: "{{codigobarras}}", descricao: "Código de Barras", exemplo: "23793.04708  90001.196063  11022.795709  8  99220000000500" },
      ], headers: [
        { text: "Variável", value: "nome" },
        { text: "Descrição", value: "descricao" },
        { text: "Exemplo", value: "exemplo" },
      ],
      loadingCreate: false,
      loadingUpdate: false,
      valid: false,
      dialog: false,
      showEditar: false,
      listaRegras: [],
      regra: {
        id: 0,
        empresa: '',
        tipo: '',
        tipo_parcela: '',
        horario: '',
        dias_vencido: '',
        data_inicial: '',
        data_final: '',
        indeterminate: true,
        mensagem: '',
        status: true,
        modelo: 'Lembrete'
      },
      isStartDatePickerOpen: false,
      isEndDatePickerOpen: false,
      localSelectedStartDateISO: '',
      localSelectedEndDateISO: '',
      ranking_header: [
        { text: 'Empresa', value: 'empresa' },
        { text: 'Mensagem Disparo', value: 'mensagem' },
        { text: 'Tipo Parcela', value: 'tipo_parcela' },
        { text: 'Tipo Contrato', value: 'tipo' },
        { text: 'Dias Antecedência', value: 'dias_vencido' },
        { text: 'Ultimo disparo', value: 'data_envio' },
        { text: 'Status', value: 'status' },
        { text: 'Ações', value: 'acao' },
      ],
      isLoading: false,
      empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ"],
    }
  },

  methods: {
    copiarTexto(texto) {
      navigator.clipboard.writeText(texto).then(() => {
      }).catch(err => {
        console.error("Erro ao copiar:", err);
      });
    },
    getStatusCor(status) {
      switch (status) {
        case 'PARQUE DAS FLORES': return 'orange darken-2'
        case 'CEMITERIO PREVIDA': return 'blue lighten-1'
        case 'MEMORIAL PARQUE MACEIÓ': return 'green lighten-1'
        case 'PARQUE DO AGRESTE': return 'cyan lighten-1'
        case 'PREVIDA': return 'blue lighten-1'
        case 'A FLOR DO CAFÉ': return 'purple lighten-1'
        default: 'success'
      }
    },
    getStatusDisparoCor(status) {
      switch (status) {
        case true: return 'green darken-1'
        case false: return 'red'
      }
    },
    getStatus(status) {
      switch (status) {
        case true: return 'ATIVO'
        case false: return 'DESATIVADO'
      }
    },
    editarRegra(item) {
      const indeterminateTemp = item.data_final === null ? true : false;
      this.regra = {
        id: item.id,
        empresa: item.empresa || '',
        tipo: item.tipo || '',
        tipo_parcela: item.tipo_parcela || '',
        horario: item.horario || '',
        dias_vencido: item.dias_vencido || '',
        data_inicial: this.convertDate(item.data_inicial || ''),
        data_final: this.convertDate(item.data_final || ''),
        indeterminate: indeterminateTemp,
        mensagem: item.mensagem || '',
        status: item.status,
        modelo: item.modelo || 'Lembrete'
      };
      // Exibir modal de edição
      this.showEditar = true;
    },
    clear_atualizacao() {
      this.consultar_regras();
      this.showEditar = false;

      this.$refs.form.reset();

      this.$nextTick(() => {
        this.regra.indeterminate = true;
      });
    },
    handleStartDateSelection() {
      this.regra.data_inicial = this.convertDate(this.localSelectedStartDateISO);
      this.isStartDatePickerOpen = false;
    },

    handleEndDateSelection() {
      this.regra.data_final = this.convertDate(this.localSelectedEndDateISO);
      this.isEndDatePickerOpen = false;
    },
    convertDate(dateString, format = 'iso') {
      if (!dateString) return '';

      if (format === 'iso') {
        // Verifica se a string está no formato ISO completo (contém 'T')
        if (dateString.includes('T')) {
          dateString = dateString.split('T')[0]; // Remove a parte do horário
        }

        // Converte de ISO (YYYY-MM-DD) para BR (DD/MM/YYYY)
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
      } else if (format === 'br') {
        // Converte de BR (DD/MM/YYYY) para ISO (YYYY-MM-DD)
        const [day, month, year] = dateString.split("/");
        return `${year}-${month}-${day}`;
      }

      return dateString; // Retorna a string original se o formato não for reconhecido
    },
    hasPermission(empresa) {
      this.user = JSON.parse(sessionStorage.getItem('user'));

      if (this.user.roles.includes("ROLE_ADMIN")) {
        return true;
      } else if (empresa === "Todas") {
        return true;
      } else {
        const requiredRoles = empresa
          .split(',')
          .map(name => 'ROLE_' + name.trim().toUpperCase().replace(/\s/g, '_'));

        const hasAnyRequiredRole = requiredRoles.some(role => this.user.roles.includes(role));
        return hasAnyRequiredRole;
      }
    },

    adiciona_regra() {
      this.loadingCreate = true;
      // Função para converter DD/MM/YYYY para ISO 8601 completo
      const formatDateToISO = (dateStr) => {
        if (!dateStr) return ''; // Evita erro se a data estiver vazia
        const [day, month, year] = dateStr.split('/');
        const date = new Date(`${year}-${month}-${day}T00:00:00.000Z`); // Garante o formato correto
        return date.toISOString(); // Converte para formato ISO completo
      };

      // Clonar o objeto regra e formatar as datas
      const regraFormatada = {
        ...this.regra,
        data_inicial: formatDateToISO(this.regra.data_inicial),
        data_final: formatDateToISO(this.regra.data_final),
      };

      if (this.$refs.form.validate()) {
        mensageria.salvar_regra(regraFormatada)
          .then(response => {

          })
          .catch(e => {
          })
          .finally(() => {
            this.consultar_regras();
            this.clear_atualizacao();
            this.valid = false;
            this.loadingCreate = false;
          });
      }
      this.loadingCreate = false;
    },
    consultar_regras() {
      mensageria.lista_regras('lembrete').then(response => {
        this.listaRegras = response.data
      }).catch(e => {
        console.log(e)
      }).finally(() => {
      })
    },
    atualizar_regra(item) {
      this.loadingUpdate = true;

      // Função para converter DD/MM/YYYY para ISO 8601 completo
      const formatDateToISO = (dateStr) => {
        if (!dateStr) return ''; // Evita erro se a data estiver vazia
        const [day, month, year] = dateStr.split('/');
        const date = new Date(`${year}-${month}-${day}T00:00:00.000Z`); // Garante o formato correto
        return date.toISOString(); // Converte para formato ISO completo
      };

      // Clonar o objeto regra e formatar as datas
      const regraFormatada = {
        ...item,
        data_inicial: formatDateToISO(item.data_inicial),
        data_final: formatDateToISO(item.data_final),
      };

      if (this.$refs.form.validate()) {
        mensageria.put_regra(item.id, regraFormatada).then(response => {
        }).catch(e => {
          console.log(e)
        }).finally(() => {
          this.consultar_regras()
          this.clear_atualizacao();
          this.valid = false;
          this.loadingUpdate = false;
        })
      }
      this.loadingUpdate = false;
    },
  },
}

</script>


<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.custom-gray-background {
  height: 90px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto !important;
  margin: -12px;
}

.v-menu__content {
  box-shadow: none !important;
  top: 4px !important;
}

.justify-center {
  justify-content: center;
}

.gray-row {
  background-color: rgb(71 130 180);
}

.cor-row {
  background-color: rgb(231 244 255);
  transition: none !important;
}

.linha {
  font-size: 11px !important;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.no-hover-effect.v-data-table tbody tr:hover {
  color: #3055a4;
}

.card-container {
  display: flex;
  justify-content: space-around;
}
</style>
